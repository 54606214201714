.bg-img {
  background-image: url("https://source.unsplash.com/1600x900/?tax");
  background-repeat: no-repeat;
  background-size: 100% 800px;
}

.bg-img-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #303030;
}

.bg-img-2::before {
  content: '';
  background-image: url("../../assets/TAX-SUVIDHAA-WHITE.jpg");
  background-size: 100% 100%;
  position: absolute;
  inset: 0;
  opacity: 0.35;
}