@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-argent {
  font-family: argent-cf, serif;
  font-style: normal;
  font-weight: 400;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.filing-option {
  @apply px-6 py-1.5 cursor-pointer self-start flex justify-center items-center space-x-2 rounded font-medium shadow shadow-slate-800 outline outline-1 outline-[#00aeca]
}

.glow {
  color: #fff;
  font-size: 20px;
  margin-top: 10px;
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    color: #fff;
    text-shadow: 0 0 0px #fff, 0 0 0px #fff, 0 0 0px #00FF00, 0 0 2px #00FF00, 0 0 2px #00FF00, 0 0 4px #00FF00, 0 0 8px #00FF00;
  }

  to {
    color: #00FF00;
    text-shadow: 0 0 0px #fff, 0 0 0px #fff, 0 0 0px #fff, 0 0 0px #fff, 0 0 0px #fff, 0 0 1px #fff, 0 0 1px #fff;
  }
}