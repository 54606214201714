@keyframes glowing {
  0% {
    box-shadow: 2px 2px 10px #3d66a7;
  }

  25% {
    box-shadow: 2px 2px 12px #3d66a7;
  }

  50% {
    box-shadow: 2px 2px 14px #3d66a7;
  }

  75% {
    box-shadow: 2px 2px 16px #3d66a7;
  }

  100% {
    box-shadow: 2px 2px 18px #3d66a7;
  }
}

.glowing-card {
  animation: glowing 350ms ease-in-out infinite alternate-reverse;
}

.active {
  border-radius: 0%;
  background-color: #111827;
}